import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FormField } from '../Layout'
import './index.css'

export const RadioMenu = props => {
  const getControlClasses = option => {
    const classes = classNames('kritik-form__radio-control', {
      'kritik-form__radio-control--active': props.value === option.value,
    })
    return classes
  }

  const getRadioKey = option => {
    return `${option.value}_${option.label}`
  }

  const renderOptions = () => {
    return props.options.map(option => {
      let label = option.label
      if (option.icon) {
        label = (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {option.icon}
            <div style={{ marginLeft: '10px' }}>{option.label}</div>
          </div>
        )
      }
      return (
        <FormControlLabel
          key={getRadioKey(option)}
          value={option.value}
          className={getControlClasses(option)}
          control={<Radio disableRipple className="kritik-form__radio" onChange={props.onChange} />}
          label={label}
          disabled={option.isDisabled === true}
        />
      )
    })
  }

  return (
    <FormField>
      <RadioGroup value={props.value} onChange={props.onChange}>
        {renderOptions()}
      </RadioGroup>
    </FormField>
  )
}

RadioMenu.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

export default RadioMenu
