import REGION_URL from './components/constants/url'
import countryNames from './components/constants/countryNames'

const redirectToDestination = (region, pathname) => {
  let destination = REGION_URL.CANADA
  switch (region) {
    case countryNames.CANADA:
      destination = REGION_URL.CANADA
      break
    case countryNames.AMERICA:
      destination = REGION_URL.AMERICA
      break
    case countryNames.EUROPE:
      destination = REGION_URL.EUROPE
      break
    case countryNames.OTHER:
      destination = REGION_URL.OTHER
      break
    default:
      destination = REGION_URL.CANADA
  }
  const url = new URL(destination + pathname)
  
  if (!url.searchParams.has('region')) {
    url.searchParams.append('region', region)
  }
  window.location =  url.toString()
}

export default {
  redirectToDestination
}