import USA from './usa.svg'
import CANADA from './canada.svg'
import EUROPE from './europe.svg'

const FLAGS = {
  USA,
  CANADA,
  EUROPE,
}

export default FLAGS
