import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export const FormFieldInput = ({ error, warning, children, className }) => {
  const classes = classNames(
    'kritik-form-field__input',
    {
      'kritik-form-field__input--warning': warning,
      'kritik-form-field__input--error': error,
    },
    className,
  )
  return <div className={classes}>{children}</div>
}

export const FormFieldLabel = props => {
  let _label = props.label
  if (props.isRequired) {
    _label += '*'
  }
  return <label className="kritik-form-field__label">{_label}</label>
}

FormFieldLabel.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string,
}

FormFieldLabel.defaultProps = {
  isRequired: false,
}

export const FormField = ({ error, className, children }) => {
  const classes = classNames('kritik-form-field', {}, className)
  return (
    <div className={classes}>
      {children}
      <FormFieldError error={error} />
    </div>
  )
}

export const FormFieldError = ({ error }) => {
  if (!error) {
    return null
  }
  const _error = `* ${error}`
  return <div className="kritik-form-field__error">{_error}</div>
}

export const FormContainer = ({ children }) => {
  return <div className="kritik-form-container">{children}</div>
}

export const FormSection = ({ hideSeparator, children }) => {
  const classes = classNames('kritik-form-section ', {
    'kritik-form-section--seamless': hideSeparator,
  })
  return <div className={classes}>{children}</div>
}

FormSection.propTypes = {
  hideSeparator: PropTypes.bool,
}

FormSection.defaultProps = {
  hideSeparator: false,
}

export const FormFieldTitle = props => {
  const SIZES = {
    SMALL: 'sm',
    MEDIUM: 'md',
    DEFAULT: 'default',
    LARGE: 'lg',
  }
  const classes = classNames(
    'kritik-form-field__title',
    {
      'kritik-form-field__title--sm': props.size === SIZES.SMALL,
      'kritik-form-field__title--md': props.size === SIZES.MEDIUM,
      'kritik-form-field__title--lg': props.size === SIZES.LARGE,
    },
    props.className,
  )
  return (
    <div className={classes}>
      {props.label}
      {props.children}
    </div>
  )
}

FormFieldTitle.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
}

FormFieldTitle.defaultProps = {
  size: 'default',
  children: null,
  className: null,
}

export const FormFieldInfo = props => {
  const TYPES = {
    ATTENTION: 'attention',
    DEFAULT: 'default',
  }

  const classes = classNames('kritik-form-field__info', {
    'kritik-form-field__info--attention': props.type === TYPES.ATTENTION,
  })
  return (
    <div className={classes}>
      {props.label}
      {props.children}
    </div>
  )
}

FormFieldTitle.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.object,
}

FormFieldTitle.defaultProps = {
  type: 'default',
}
