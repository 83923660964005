import React from 'react'
import {
  SignupContainer,
  SignupContent,
  SignupHeader,
  SignupText,
  SignupButtons,
  SignupButton
} from '../RedirectWrapper'
import RadioMenu from '../Form/Radio'
import FLAGS from '../../images/flags'
import countryNames from '../constants/countryNames'
import utils from '../../utils'

const radioOptions = [
  { value: countryNames.AMERICA, label: countryNames.AMERICA, icon: <img src={FLAGS.USA} alt="flag of USA" /> },
  { value: countryNames.CANADA, label: countryNames.CANADA, icon: <img src={FLAGS.CANADA} alt="flag of Canada" /> },
  { value: countryNames.EUROPE, label: countryNames.EUROPE, icon: <img src={FLAGS.EUROPE} alt="flag of Europe" /> },
  { value: countryNames.OTHER, label: countryNames.OTHER, icon: null },
]

function Redirect(props) {

  const setLocalStorageUponRedirect = value => {
    localStorage.setItem('REGION', value)
  }

  const handleButtonClicked = () => {
    const region = localStorage.getItem('REGION') || null
    if (region)
      utils.redirectToDestination(region, props.pathname)
  }

  const renderRedirectContent = () => {
    return (
      <SignupContent>
        <SignupHeader size="xl"> Please select your institution's region </SignupHeader>
        <SignupText weight="strong">My institution is in</SignupText>
        <RadioMenu options={radioOptions} onChange={(e) => {
          const region = e.target.value
          setLocalStorageUponRedirect(region)
        }} />
        <SignupButtons> 
          <SignupButton onClick={handleButtonClicked}> 
              Next 
          </SignupButton> 
        </SignupButtons>
      </SignupContent>
    )
  }

  return <SignupContainer>{renderRedirectContent()}</SignupContainer>
}

export default Redirect
