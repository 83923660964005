import React from 'react'
import Redirect from '../components/Redirect/Redirect'

function RedirectContainer(props) {
  return (
    <div>
      <Redirect pathname={props.pathname} />
    </div>
  )
}

export default RedirectContainer
