import React from 'react'
import classNames from 'classnames'
import images from './images'
import Container from '@material-ui/core/Container'
import './App.css'
import RedirectContainer from './containers/Redirect'
import utils from './utils'

function App() {
  const renderNavBar = () => {
    return renderLinkToKritik()
  }

  const renderLinkToKritik = () => {
    return (
      <div className="main-wrapper__link-to-home">
        <a href="https://www.kritik.io" target="_self">
          <img src={images.LOGO} alt="Kritik" />
        </a>
      </div>
    )
  }

  const subStrings = window.location.href.split('#!')
  let path = '/'
  if (subStrings.length > 1) {
    path = path + subStrings[subStrings.length - 1]
  }
  const searchString = window.location.search
  const urlParams = new URLSearchParams(searchString)
  const isRedirect = urlParams.get('redirect')

  const region = localStorage.getItem('REGION')
  if (isRedirect === 'false') {
    localStorage.clear()
  } else if (region) {
    return utils.redirectToDestination(region, path)
  }

  const containerClasses = classNames('main-wrapper main-wrapper__top-nav')
  return (
    <div className={containerClasses}>
      {renderNavBar()}
      <Container maxWidth="lg" className="main-wrapper__content">
        <RedirectContainer pathname={path} />
      </Container>
    </div>
  )
}

App.propTypes = {}

export default App
