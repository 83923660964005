import React from 'react'
import classNames from 'classnames'

const getStyle = (className, color, weight) => {
  const classes = classNames(className, {
    'color-dark': color === 'dark',
    'color-default': color === 'default',
    'color-light': color === 'light',
    'color-attention': color === 'attention',
    'weight-strong': weight === 'strong',
    'weight-default': weight === 'normal',
  })

  return classes
}

export const SignupMainHeader = ({ children }) => {
  return <div className="signup-content__header">{children}</div>
}

export const SignupMainTitle = ({ children }) => {
  return <div className="signup-content__main-title">{children}</div>
}

export const SignupContent = ({ children }) => {
  return (
    <div className="signup-content__body">
      <div className="signup-content__wrapper">{children}</div>
    </div>
  )
}

export const SignupHeader = ({ size = 'md', color, weight, children }) => {
  let headerClass = `signup-content__title-${size}`
  headerClass = getStyle(headerClass, color, weight)
  return <div className={headerClass}>{children}</div>
}

export const SignupText = ({ children, color, weight }) => {
  const textClass = getStyle('signup-content__text', color, weight)
  return <div className={textClass}>{children}</div>
}

export const SignupButtons = ({ children }) => {
  return <div className="signup-content__button-container">{children}</div>
}

export const SignupButton = ({ onClick, children }) => {
  return <button className="signup-content__button" onClick={onClick}>{children}</button>
}

export const SignupFooter = ({ children }) => {
  return <div className="signup-content__footer">{children}</div>
}
