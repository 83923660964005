import React from 'react'
import classNames from 'classnames'
import images from '../../images'
import './Container.css'

const SignupContainer = ({ className, status, graphic, children }) => {
  const classes = classNames('signup-container', {}, className)

  const getMediaFooter = () => {
    if (status === 'success') {
      return images.BUBBLE_BG_SUCCESS
    }
    return images.BUBBLE_BG
  }

  const getGraphicURL = () => {
    switch (graphic) {
      case 'rocket':
        return images.ROCKETSHIP
      case 'checkout':
        return images.CHECKOUT
      case 'checkout-success':
        return images.CHECKOUT_SUCCESS
      case 'invite':
        return images.INVITE
      default:
    }
  }

  const renderGraphicsPanel = () => {
    const mediaClasses = classNames('signup-media', {
      success: status == 'success',
      error: status == 'error',
    })

    return (
      <div className={mediaClasses}>
        <div className="signup-media__graphic">
          <img className="" src={getGraphicURL()} />
        </div>
        <img className="signup-media__footer" src={getMediaFooter()} alt="Bubbles" />
      </div>
    )
  }

  return (
    <div className={classes}>
      <div className="signup-container__lpanel">{renderGraphicsPanel()}</div>
      <div className="signup-container__rpanel">
        <div className="signup-content">{children}</div>
      </div>
    </div>
  )
}

export default SignupContainer
