import BUBBLE_BG from './bg-bubbles-purple.svg'
import BUBBLE_BG_SUCCESS from './bg-bubbles-green.svg'
import LOGO from './logo.svg'
import ROCKETSHIP from './rocketship.svg'

export default {
  BUBBLE_BG,
  BUBBLE_BG_SUCCESS,
  LOGO,
  ROCKETSHIP,
}
